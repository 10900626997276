<template>
  <section>
    <title-section
      title="Redes Sociales"
    >
      <template v-if="!loading && Object.keys(socialMediaData).length !== empty">
        <template v-if="modeEdit">
          <div class="level-item">
            <button
              class="button button_micro_dark_outlined"
              @click="resetEdit()"
              :disabled="loadingBtn"
            >
              CANCELAR
            </button>
          </div>
          <div class="level-item">
            <button
              class="button button_micro_dark"
              :class="{ 'is-loading' : loadingBtn }"
              :disabled="loadingBtn"
              @click="getLocalDataAction()"
            >
              GUARDAR
            </button>
          </div>
        </template>
        <template v-else>
          <div class="level-item">
            <button class="button button_micro_dark" @click="modeEdit = true">
              EDITAR
            </button>
          </div>
        </template>
      </template>
    </title-section>
    <template v-if="loading">
      <Loading />
    </template>
    <template v-else>
      <social-media-main
        v-if="Object.keys(socialMediaData).length !== empty"
        ref="socialMediaMainRef"
        :modeEdit="modeEdit"
        :loading="loading"
        :socialMediaData="socialMediaData"
        @set-data-update="setDataUpdate"
      />
      <template v-else>
        <div class="hero-body">
          <div class="container">
            <base-not-data
              title="No hay datos para mostrar"
            >
            </base-not-data>
          </div>
        </div>
      </template>
    </template>
  </section>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'SocialMedia',
  components: {
    TitleSection: () => import('@/components/UI/TitleSection.vue'),
    SocialMediaMain: () => import('@/components/SocialMedia/SocialMediaMain.vue'),
    BaseNotData: () => import('@/components/UI/BaseNotData.vue'),
    Loading: () => import('@/components/UI/Loading.vue')
  },
  data () {
    return {
      modeEdit: false,
      loading: false,
      loadingBtn: false,
      socialMediaData: {},
      empty: 0
    }
  },
  methods: {
    ...mapActions(['returnPrevView', 'getSocialMedia', 'updateSocialMedia']),
    async getSocialMediaAction () {
      const { success, socialMediaList } = await this.getSocialMedia()
      if (success) {
        this.socialMediaData = socialMediaList
      }
      this.loading = false
    },
    resetEdit () {
      this.modeEdit = false
      this.loading = true
      this.getSocialMediaAction()
    },
    getLocalDataAction () {
      this.$refs.socialMediaMainRef.getLocalData()
    },
    async setDataUpdate (dataUpdate) {
      this.loadingBtn = true
      const resp = await this.updateSocialMedia(dataUpdate)
      if (resp) { await this.resetEdit() }
      this.loadingBtn = false
    }
  },
  async beforeMount () {
    this.loading = true
    this.getSocialMediaAction()
  }
}
</script>

<style lang="scss" scoped>
  .button {
    font-size: 14px;
    width: 150px;
  }
</style>
